
<template lang="html">
    <svg width="25px" height="33px" viewBox="0 0 25 33" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
        <g id="Desktop" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g id="SYN001---DSKTP---Nová-smlouva---výběr-zdroje" transform="translate(-800.000000, -266.000000)">
                <rect fill="#FFFFFF" x="0" y="0" width="1200" height="893"></rect>
                <g id="karty-3" transform="translate(433.000000, 234.000000)" stroke="#5E239E">
                    <g id="ico/ico-novy-dokument-purple" transform="translate(357.000000, 29.000000)">
                        <g id="Group" transform="translate(11.100000, 3.700000)">
                            <g id="ico/ico-vzory-1" fill-rule="nonzero" stroke-width="2">
                                <path d="M9.8667621,8.63333333 C13.1557095,8.63333333 15.0859548,8.63333333 15.6574981,8.63333333 C16.514813,8.63333333 16.9663943,7.59123423 16.9663943,6.22802318 C16.9663943,5.31921582 16.9663951,3.65431921 16.9663967,1.23333333" id="Path-16" transform="translate(13.416579, 4.933333) scale(-1, 1) translate(-13.416579, -4.933333) "></path>
                                <path d="M7.4,25.9 C3.86308078,25.9 1.82771412,25.9 1.29390003,25.9 C0.493178879,25.9 2.68135583e-16,25.2707098 0,23.9036249 C0,22.5365401 7.17888334e-13,3.8304169 0,2.70698932 C-7.17858541e-13,1.58356174 0.316480833,1.2165258 1.29390003,1.2165258 C1.94551282,1.2165258 5.26516278,1.2165258 11.2528499,1.2165258 L17.2666667,7.78132321 L17.1744044,16.0898513" id="Path-3"></path>
                            </g>
                            <path d="M17.2666667,20.9666667 L17.2666667,30.8333333" id="Line" stroke-width="2" stroke-linecap="square" fill-rule="nonzero"></path>
                            <path d="M12.3333333,25.9 L22.2,25.9" id="Line-Copy" stroke-width="2" stroke-linecap="square" fill-rule="nonzero"></path>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </svg>
</template>


<script>
    export default {
        name: 'IcoCreate'
    }
</script>
