
<template lang="html">
    <svg width="34px" height="29px" viewBox="0 0 34 29" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
        <g id="Desktop" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g id="SYN001---DSKTP---Nová-smlouva---výběr-zdroje" transform="translate(-472.000000, -354.000000)">
                <rect fill="#FFFFFF" x="0" y="0" width="1200" height="893"></rect>
                <g id="karty-3" transform="translate(433.000000, 234.000000)" fill="#0062FF">
                    <g id="ico/ico-dropbox" transform="translate(39.000000, 120.000000)">
                        <g id="Group-10">
                            <polygon id="Path-22" fill-rule="nonzero" points="0 5.85930187 16.8490939 16.1182901 8.42454694 21.4357587 0 16.1182901 16.8490939 5.85930187 8.42454694 0"></polygon>
                            <polygon id="Path-22-Copy" fill-rule="nonzero" points="16.5641026 5.85930187 33.4131964 16.1182901 24.9886495 21.4357587 16.5641026 16.1182901 33.4131964 5.85930187 24.9886495 0"></polygon>
                            <polygon id="Path-23" fill-rule="nonzero" points="8.36907193 23.5616557 16.6929138 18.1133135 24.6481999 23.5616557 16.6929138 28.904132"></polygon>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </svg>
</template>


<script>
    export default {
        name: 'IcoDropbox'
    }
</script>
